import React from "react";
import Layout from "../styles/layout";
import { Typography, Box, Container } from "@mui/material";
import Seo from "../components/seo";
import AffiliateView from "../components/affiliateView";
import AuthorAndSocialMediaComponent from "../components/authorAndSocialMediaComponent";

export default function Sponsorship({ props, location }) {
  return (
    <Layout location={location}>
      <Seo
        title="Sponsorship"
        description="With currently over 35000 unique visitors every month, tanaschita.com
        is publishing a new article every week and is constantly growing in
        the iOS and Swift community - which may provide a great audience for
        you as a sponsor. Advertisement example."
      />
      <Container>
        <Typography variant="h1">Sponsorship preview</Typography>
        <Typography>
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua. At vero eos et accusam et justo duo dolores et ea
          rebum.
        </Typography>
        <AffiliateView type="sponsor-proxyman"></AffiliateView>
        <Box height="24px"></Box>
        <Typography>
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua. At vero eos et accusam et justo duo dolores et ea
          rebum.
        </Typography>
        <Box height="24px"></Box>
        <Typography>
          Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse
          molestie consequat, vel illum dolore eu feugiat nulla facilisis at
          vero eros et accumsan et iusto odio dignissim qui blandit praesent
          luptatum zzril delenit augue duis dolore te feugait nulla facilisi.
        </Typography>
        <Box height="24px"></Box>
        <AuthorAndSocialMediaComponent></AuthorAndSocialMediaComponent>
      </Container>
    </Layout>
  );
}
